import React, { useEffect } from "react";
import "./Carrito.css";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaPlus, FaMinus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { useAuth } from "../context/authContext"; // Asegúrate de importar tu contexto de autenticación

const Carrito = ({ productos, setProductos, setBlinking }) => {
  const navigate = useNavigate();
  const { estado } = useAuth(); // Usamos el hook de autenticación para verificar el estado del usuario

  const notify = () => {
    toast.error("Necesitas Iniciar Sesión!", {
      position: "top-center",
    });
  };

  const notificacion2 = () => {
    toast.error("Necesitas agregar productos!", {
      position: "top-center",
    });
  };

  // Nueva notificación para stock máximo alcanzado
  const notifyStockMaximo = ( stockDisponible) => {
    toast.warning(`Solo puedes pedir ${stockDisponible} unidades`, {
      position: "top-center",
    });
  };

  useEffect(() => {
    // Almacenar los productos en las cookies cada vez que se actualiza el estado
    Cookies.set("cart", JSON.stringify(productos));
  }, [productos]);

  const aumentarCantidad = (id) => {
    const nuevosProductos = productos.map((prod) => {
      if (prod.id === id) {
        if (prod.cantidad < prod.stock) {
          return { ...prod, cantidad: prod.cantidad + 1 };
        } else {
          // Mostrar la notificación de que no se puede agregar más del stock disponible
          notifyStockMaximo(prod.stock);
        }
      }
      return prod;
    });
    setProductos(nuevosProductos);
  };

  const disminuirCantidad = (id) => {
    const nuevosProductos = productos.map((prod) =>
      prod.id === id && prod.cantidad > 1
        ? { ...prod, cantidad: prod.cantidad - 1 }
        : prod
    );
    setProductos(nuevosProductos);
  };

  const eliminarProducto = (id) => {
    const nuevosProductos = productos.filter((prod) => prod.id !== id);
    setProductos(nuevosProductos);
  };

  const direccionar = () => {
    navigate("/productos");
  };

  const handleFinalizarPedido = () => {
    if (estado) {
      if (productos.length === 0) {
        notificacion2();
      } else {
        // Aquí se quita setProductos del estado que se pasa a través de navigate
        navigate("/finalizarpedido", { state: { productos } });
      }
    } else {
      notify();
      setBlinking(true);
      setTimeout(() => setBlinking(false), 2000);
    }
  };

  return (
    <div className="carrito-container">
      <h2>Mi Carrito</h2>
      {productos.length > 0 ? (
        productos.map((producto) => (
          <div key={producto.id} className="producto">
            <img
              src={producto.imagen}
              alt={producto.nombre}
              className="producto-imagen"
            />
            <div className="producto-info">
              <h3>{producto.nombre}</h3>
              <p>Nombre: {producto.titulo}</p>
              <div className="cantidad-container">
                <button
                  onClick={() => disminuirCantidad(producto.id)}
                  className="btn-cantidad"
                >
                  <FaMinus size={16} color="red" />
                </button>
                <span className="cantidad">{producto.cantidad}</span>
                <button
                  onClick={() => aumentarCantidad(producto.id)}
                  className="btn-cantidad"
                >
                  <FaPlus size={16} color="green" />
                </button>
              </div>
              <p>Stock disponible: {producto.stock}</p> {/* Mostrar el stock disponible */}
            </div>
            <button
              onClick={() => eliminarProducto(producto.id)}
              className="btn-eliminar"
            >
              <FaTrash size={20} color="red" />
            </button>
          </div>
        ))
      ) : (
        <p>NO HAY PRODUCTOS AÑADIDOS A CARRITO</p>
      )}
      <div className="resumen-compra">
        <h3>Resumen de compra</h3>
        <button onClick={handleFinalizarPedido} className="btn-finalizar">
          Finalizar Pedido
        </button>
        <button onClick={direccionar} className="btn-continuar">
          Continuar Pedido
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Carrito;
