import React, { useState } from "react";
import "./Login.css";
import { useAuth } from "../context/authContext"; // Asegúrate de que la ruta sea correcta
import { NavLink, useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../conexion/firebase";

// Importar los componentes de Cliente
import { Encabezado } from "../components-Cliente/Encabezado";
import Informacion from "../components-Cliente/informacion";

// Importar el componente de Administrador
import AgregarPro from "../components-Administrador/agregarpro";
import { EncabezadoAdmin } from "../components-Administrador/EncabezadoAdmin";

export function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setEstado, login, setDatosUsuario, setEstado2 } = useAuth(); // Asegúrate de incluir setDatosUsuario
  const navigate = useNavigate();
  const [mensaje, setMensaje] = useState("");
  const [rol, setRol] = useState(localStorage.getItem("rol")); // Manejar el rol en el estado

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Limpiar localStorage antes de iniciar sesión para que no guarde Rol Anterior
    localStorage.removeItem("rol");
    localStorage.removeItem("login");
    localStorage.removeItem("estado2");

    try {
      const userLogin = await login(user.email, user.password);

      if (userLogin) {
        // Verificar si el correo ha sido verificado
        if (!userLogin.user.emailVerified) {
          setMensaje("Por favor verifica tu correo antes de iniciar sesión.");
          return;
        }

        setEstado(true);
        localStorage.setItem("login", "true");
        setMensaje("");

        const refCuenta = collection(db, "cuenta");
        const q = query(
          refCuenta,
          where("usuario_uid", "==", userLogin.user.uid)
        );
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          const datos = { ...doc.data(), id: doc.id };

          console.log(
            "Datos del usuario:",
            datos.rol,
            "Nombre: ",
            datos.nombre
          );
          setDatosUsuario([datos]); // Usar setDatosUsuario para actualizar el estado
          localStorage.setItem("cuenta", JSON.stringify(datos));
          setEstado2(true);
          localStorage.setItem("estado2", "true");

          setRol(datos.rol);

          if (datos.rol === "Administrador") {
            console.log("Redirigiendo a inicio");
            navigate("/inicio");
          } else if (datos.rol === "Cliente") {
            console.log("Redirigiendo a productos");
            navigate("/productos");
          }
        } else {
          console.log("No se encontraron datos para el usuario.");
          // navigate("/InformacionPerfil");
          navigate("/InformacionPerfil", { replace: true }); // Redirige a la página de información de perfil
        }
      }
    } catch (error) {
      if (error.code === "auth/invalid-credential"){
        setMensaje("Error al verificar credenciales. Por favor verifica tus datos.");
      } else if ("auth/too-many-requests") {
        setMensaje("Haz realizado demasiados intentos. Por favor, espera unos minutos antes de intentar nuevamente.");
      } else {
        setMensaje("Error al iniciar sesión. Por favor, verifica tu correo electrónico.");
      }
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Correo electrónico
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            placeholder="ejemplo@gmail.com"
            required
          />
        </label>
        <label htmlFor="password">
          Contraseña
          <div className="password-container">
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              id="password"
              onChange={handleChange}
              placeholder="******"
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              <i
                className={passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}
              ></i>
            </button>
          </div>
        </label>

        <button type="submit" className="btn-ingresar">
          INGRESAR
        </button>

        {mensaje && <p className="mensaje">{mensaje}</p>}

        <div className="action-buttons">
          <NavLink className="btn-texto" to="/RestablecerPassword">
            ¿Olvidaste tu Contraseña?
          </NavLink>
          <NavLink className="btn-texto" to="/register">
            ¿No tienes cuenta?
          </NavLink>
        </div>
      </form>

      {/* Renderizar encabezados según el rol */}
      {rol === "Cliente" && (
        <>
          <Encabezado />
          <Informacion />
        </>
      )}

      {rol === "Administrador" && (
        <>
          <EncabezadoAdmin />
          <AgregarPro />
        </>
      )}
    </div>
  );
}

export default Login;
