import React from 'react';
import './Footer.css';

const PieDePagina = () => {
  return (
    <div className="pie-de-pagina">
      <p className="informacion">&copy; 2024 Persia. Todos los derechos reservados.</p>
      <p className="informacion">Contacto: persia@gmail.com | Teléfono: +502 54649096</p>
    </div>
  );
};

export default PieDePagina;
