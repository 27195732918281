import { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification, // Importar esta función
} from "firebase/auth";
import { auth } from "../conexion/firebase";


export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("No existe un proveedor de usuario");
  return context;
};

export function AuthProvider({ children }) {
  
  const [estado, setEstado] = useState(false);
  const [estado2, setEstado2] = useState(false);
  const [datosUsuario, setDatosUsuario] = useState([]);

  const signup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      
      // Enviar el correo de verificación después del registro
      await sendEmailVerification(userCredential.user);
      
      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      const userLogin = await signInWithEmailAndPassword(auth, email, password);
      return userLogin;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("login")) {
      setEstado(true);
    } else{
      setEstado(false);
    }

    if (localStorage.getItem("estado2")){
      setEstado2(true);
    } else{
      setEstado2(false);
    }
  }, []);


  return (
    <authContext.Provider value={{ signup, login, estado, setEstado, datosUsuario, setDatosUsuario,estado2, setEstado2}}>
      {children}
    </authContext.Provider>
  );
}
